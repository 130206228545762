<div class="skills-container">
    <div class="skills-title-container">
        <div class="skills-title-content-container">
            <img class="skills-title-background-image" ngSrc="assets/images/background/white-blue-pink-cloud-800.png" height="577"
                 width="800" alt="skill-cloud">
            <p class="skills-title-text">Skills</p>
        </div>
    </div>
    <div class="skills-content-container">
        <div class="skills-graph-title-container">
            <p class="skills-graph-title-text">{{selectedIcon.id}}</p>
        </div>
        <div class="skills-graph-container">
            <img class="skills-graph-img" src="{{selectedIcon.graphImagePath}}" alt="skill-graph">
        </div>
        <div class="skills-icon-container">
            <div *ngFor="let skill of skills; let i = index">
                <img class="skills-icon-img"
                     src="{{skill.selected ?  skill.selectedImgPath : skill.imgPath}}"
                     (click)="onSelectSkill(skill, i)"
                     (mouseenter)="skill.imgPath = skill.selectedImgPath"
                     (mouseleave)="skill.imgPath = skill.defaultImgPath" alt="skill-image">
            </div>
        </div>
    </div>
</div>