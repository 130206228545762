import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {StateService} from "../services/state/state.service";

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  standalone: true,
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent {

  constructor(private stateService: StateService) {
  }

  onOpenNav(): void {
    this.stateService.isNavOpen$.next(true);
  }

}
