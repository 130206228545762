<div class="nav-bar-container">
    <div class="nav-bar-top-border">
    </div>
    <div class="nav-bar-header-text-container">
        <p class="nav-bar-header-text">Zane Jackson</p>
        <p class="nav-bar-header-sub-text">My Portfolio</p>
    </div>
    <div class="nav-bar-hamburger-container" (click)="onOpenNav()">
        <img class="nav-bar-hamburger-background"
             src="/assets/images/background/pow.jpeg" alt="hamburger-cloud">
        <img class="nav-bar-hamburger"
             src="/assets/images/icons/hamburger-orange.png" alt="hamburger">
    </div>
    <div class="nav-bar-bottom-border">
    </div>
</div>
