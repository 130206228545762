import {Component, ElementRef, ViewChild} from '@angular/core';
import {StackModel} from "./stack.model";
import {NgForOf, NgOptimizedImage} from "@angular/common";


@Component({
  selector: 'app-stack',
  templateUrl: './stack.component.html',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgForOf
  ],
  styleUrls: ['./stack.component.scss']
})
export class StackComponent {

  @ViewChild('stack') stackRef: ElementRef = {} as ElementRef;


  springBootLogo = '/assets/images/icons/apps/spring-boot.svg'
  angularLogo = '/assets/images/icons/apps/angular.svg'
  uxLogo = '/assets/images/icons/apps/ux.png'

  skills: StackModel[] = [
    {
      name: 'spring boot',
      icon: this.springBootLogo,
      description: 'As a skilled Spring Boot Developer with expertise in Java, I have a proven track record of building robust and scalable applications. ' +
          'I have a deep understanding of Java development and security principles, ensuring clean and maintainable code.'
    },
    {
      name: 'angular',
      icon: this.angularLogo,
      description: 'As an accomplished Angular developer, I have a strong background in creating scalable and secure web experiences. ' +
          'With a keen eye for detail and a passion for web design, I continuously strive to craft highly engaging user-centric applications.'
    },
    {
      name: 'user experience',
      icon: this.uxLogo,
      description: 'As a passionate UI/UX Developer, I approach web design as a form of art. ' +
          'I bridge the gap between aesthetics and functionality, ensuring that the end product not only looks beautiful, but also provides a seamless user journey. '
    },

  ]


}
