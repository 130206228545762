import {Component} from '@angular/core';
import { NgOptimizedImage } from '@angular/common'


@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent {

  resumeSource = 'assets/resume/zane-jackson-resume-2024.pdf'

  openResume(): void {
    window.open(this.resumeSource, '_blank');
  }

}
