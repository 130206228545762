<div class="contact-container">
    <div class="contact-title-container">
        <div class="contact-title-content-container">
            <img class="contact-title-background-image"
                 ngSrc="assets/images/background/white-blue-pink-cloud-800.png"
                 height="577" width="800" alt="contact-cloud">
            <p class="contact-title-text">Let's get<br>in touch!</p>
        </div>
    </div>
    <form [formGroup]="formData" name="contact-form" class="contact-form" method="POST" netlify
          (submit)="onSubmit(this.formData)">
        @for (section of formSections; track section.id) {
            <div class="contact-form-row">
                <div class="contact-form-input-container">
                    <p class="contact-header">{{ section.headerName }}</p>
                    @if (!section.isTextArea) {
                        <input formControlName="{{section.id}}"
                               (focus)="onInputFocus(section.id)"
                               (blur)="onInputBlur(section.id)"
                               type="text"
                               class="contact-input"
                               name="{{section.id}}"
                               [placeholder]="section.placeholder">
                    } @else {
                        <textarea (focus)="onInputFocus(section.id)"
                                  (blur)="onInputBlur(section.id)"
                                  formControlName="{{section.id}}"
                                  contenteditable="true"
                                  class="contact-input-message"
                                  name="{{section.id}}"
                                  [placeholder]="section.placeholder"></textarea>
                    }
                </div>
                <div class="contact-icon-container">
                    @if (formData.get(section.id)?.invalid && formData.get(section.id)?.touched) {
                        <img class="contact-validation-icon"
                             ngSrc="assets/images/icons/error-icon.png" height="512" width="512" alt="validation-error">
                    }
                    @if (formData.get(section.id)?.valid) {
                        <img class="contact-validation-icon"
                             ngSrc="assets/images/icons/accept-icon.png" height="512" width="512" alt="validation-confirm">
                    }
                </div>
            </div>
        }
        <div class="contact-form-submit-button-container">
            <button class="contact-form-submit-button" type="submit">Send it!</button>
        </div>
    </form>
</div>
