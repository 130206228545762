import {Component} from '@angular/core';
import {NgForOf, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  standalone: true,
    imports: [
        NgOptimizedImage,
        NgForOf
    ],
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent {

  experiences = [
    {
      logo: 'assets/images/icons/companies/cardinal-logo.svg',
      tenure: '2021-Present',
      tenure2: '',
      jobTitle: 'Senior Full Stack Engineer',
      jobTitle2: '',
      info: 'Managed security \n stuff',
      keypoint1: 'Established a cross-functional ecosystem in a domain driven architecture utilizing Google Cloud Services, Spring Boot, and Angular. Primarily worked to create cost effective shipping rates between suppliers and customers.',
      keypoint2: ' Elevated client-side security, responsiveness, and coding practices to boost code maintainability, simplifying updates and future developments.'
    },
    {
      logo: 'assets/images/icons/companies/nationwide-logo.svg',
      tenure: '2019-2021',
      tenure2: '2014-2016',
      jobTitle: 'Senior Java Consultant &',
      jobTitle2: 'ODM Developer',
      info: 'Managed security \n stuff',
      keypoint1: 'Orchestrated the migration of a legacy monolithic architecture to a dynamic cloud-based microservices framework. Leveraged technologies such as Spring Boot, Spring Batch, Docker, Kubernetes, and Concourse pipelines to meticulously design and execute the transformation.',
      keypoint2: 'Developed an innovative network of interconnected services, where the central component leveraged Apache PDFBox to seamlessly transition from the conventional batch-based generation of correspondence to a dynamic and real-time PDF generation process.'
    },
    {
      logo: 'assets/images/icons/companies/chase-created.svg',
      tenure: '2016-2019',
      tenure2: '',
      jobTitle: 'Java Developer (ODM)',
      jobTitle2: '',
      info: 'Managed security \n stuff',
      keypoint1: 'Engaged in ODM rule development to fortify banking security and proactively identify fraudulent activities.',
      keypoint2: 'Automated rule testing using synthetic Java transactions for enhanced rule accuracy and system reliability by simulating real world scenarios.'
    }
  ]

}
