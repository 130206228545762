import {Component, OnInit} from '@angular/core';
import {Icon} from "../models/icon.model";
import {StateService} from "../services/state/state.service";
import {NgForOf, NgOptimizedImage} from "@angular/common";


@Component({
    selector: 'app-skills',
    templateUrl: './skills.component.html',
    styleUrls: ['./skills.component.scss'],
    imports: [
        NgForOf,
        NgOptimizedImage
    ],


    standalone: true
})
export class SkillsComponent implements OnInit {

    skills: Icon[] = [
        {
            id: 'spring boot',
            imgPath: 'assets/images/icons/skills/spring.svg',
            defaultImgPath: 'assets/images/icons/skills/spring.svg',
            selectedImgPath: 'assets/images/icons/skills/spring-selected.svg',
            graphImagePath: 'assets/images/graphs/java.png',
            selected: true
        },
        {
            id: 'angular',
            imgPath: 'assets/images/icons/skills/angular.svg',
            defaultImgPath: 'assets/images/icons/skills/angular.svg',
            selectedImgPath: 'assets/images/icons/skills/angular-selected.svg',
            graphImagePath: 'assets/images/graphs/docker.png',
            selected: false
        },
        {
            id: 'react',
            imgPath: 'assets/images/icons/skills/react.svg',
            defaultImgPath: 'assets/images/icons/skills/react.svg',
            selectedImgPath: 'assets/images/icons/skills/react-selected.svg',
            graphImagePath: 'assets/images/graphs/javascript.png',
            selected: false
        },
        {
            id: 'html',
            imgPath: 'assets/images/icons/skills/html5.svg',
            defaultImgPath: 'assets/images/icons/skills/html5.svg',
            selectedImgPath: 'assets/images/icons/skills/html5-selected.svg',
            graphImagePath: 'assets/images/graphs/html.png',
            selected: false
        },
        {
            id: 'python',
            imgPath: 'assets/images/icons/skills/python.svg',
            defaultImgPath: 'assets/images/icons/skills/python.svg',
            selectedImgPath: 'assets/images/icons/skills/python-selected.svg',
            graphImagePath: 'assets/images/graphs/python.png',
            selected: false
        },
        {
            id: 'docker',
            imgPath: 'assets/images/icons/skills/docker.svg',
            defaultImgPath: 'assets/images/icons/skills/docker.svg',
            selectedImgPath: 'assets/images/icons/skills/docker-selected.svg',
            graphImagePath: 'assets/images/graphs/javascript.png',
            selected: false
        },
        {
            id: 'kubernetes',
            imgPath: 'assets/images/icons/skills/kubernetes.svg',
            defaultImgPath: 'assets/images/icons/skills/kubernetes.svg',
            selectedImgPath: 'assets/images/icons/skills/kubernetes-selected.svg',
            graphImagePath: 'assets/images/graphs/react.png',
            selected: false
        },


    ];

    selectedIcon: Icon = this.skills[0];

    constructor(private stateService: StateService) {
    }

    ngOnInit(): void {
        this.stateService.selectedSkill$.subscribe(selectedIcon => {
            this.selectedIcon = selectedIcon;
        })
    }

    onSelectSkill(skill: Icon, index: number) {
        this.skills.forEach(skill => skill.selected = false);
        this.skills[index].selected = true;
        this.stateService.selectedSkill$.next(skill);
    }

}
