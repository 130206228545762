<app-navigation-bar></app-navigation-bar>
<app-navigation-menu></app-navigation-menu>
<section id="home">
    <app-about-me></app-about-me>
</section>
<section id="stack">
    <app-stack></app-stack>
</section>
<section id="skills">
    <app-skills></app-skills>
</section>
<section id="experience">
    <app-experience></app-experience>
</section>
<section id="contact">
    <app-contact></app-contact>
</section>


