<div class="stack-container">
    <div class="stack-title-container">
        <div class="stack-title-content-container">
            <img class="stack-title-background-image"
                 ngSrc="assets/images/background/orange-bubble-800w.png" height="665" width="800" alt="stack-cloud">
            <p class="stack-title">Development<br>Expertise</p>
        </div>
    </div>
    <div class="stack-container-wrapper">
        <div class="stack-item-container" *ngFor="let skill of skills">
            <img class="stack-image" src="{{skill.icon}}" alt="stack-icon">
            <p class="stack-name">{{skill.name}}</p>
            <div class="stack-description-container">
                <p class="stack-description">{{skill.description}}</p>
            </div>
        </div>
    </div>
</div>
