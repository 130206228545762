<div class="experience-container">
    <div class="experience-title-container">
        <div class="experience-title-content-container">
            <img class="experience-title-background-image"
                 ngSrc="assets/images/background/cloud-yellow-800w.png"
                 height="644"
                 width="800" alt="experience-cloud">
            <p class="experience-title-text">Experience</p>
        </div>
    </div>
    <div class="experience-items-container">
        <div class="experience-item-container" *ngFor="let experience of experiences">
            <img class="experience-logo" ngSrc="{{experience.logo}}" height="16" width="16" alt="exp-logo">
            <div class="experience-job-title-header-container">
                <p style="white-space: pre-line" class="experience-job-title-header">Role</p>
            </div>
            <div class="experience-job-title-container">
                <p class="experience-job-title-text">
                    {{experience.jobTitle}}
                    <br>
                    {{experience.jobTitle2}}</p>
            </div>
            <div class="experience-tenure-header-container">
                <p style="white-space: pre-line" class="experience-tenure-header-text">Tenure</p>
            </div>
            <div class="experience-tenure-text-container">
                <p style="white-space: pre-line" class="experience-tenure-text">{{experience.tenure}}
                    <br>{{experience.tenure2}}</p>
            </div>
            <div class="experience-key-points-container">
                <p class="experience-key-points-text">
                    {{experience.keypoint1}}
                </p>
                <p class="experience-key-points-text">
                    {{experience.keypoint2}}
                </p>
            </div>
        </div>
    </div>
</div>
