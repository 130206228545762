import {Component, OnInit} from '@angular/core';
import {Meta} from "@angular/platform-browser";
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(private meta: Meta) { }


  ngOnInit(): void {
    this.meta.updateTag({ property: 'og:image', content: 'assets/images/profile-pic.jpg' });
    this.meta.updateTag({ property: 'description', content: 'Zane Jackson - Senior Full Stack Developer - Please come in and explore my portfolio!' });
  }
}
