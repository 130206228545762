import {Component} from '@angular/core';
import {NavigationBarComponent} from "../navigation-bar/navigation-bar.component";
import {NavigationMenuComponent} from "../navigation-menu/navigation-menu.component";
import {AboutMeComponent} from "../about-me/about-me.component";
import {StackComponent} from "../stack/stack.component";
import {SkillsComponent} from "../skills/skills.component";
import {ExperienceComponent} from "../experience/experience.component";
import {ContactComponent} from "../contact/contact.component";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  standalone: true,
  imports: [
    NavigationBarComponent,
    NavigationMenuComponent,
    AboutMeComponent,
    StackComponent,
    SkillsComponent,
    ExperienceComponent,
    ContactComponent
  ],
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

}
