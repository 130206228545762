import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {Icon} from "../../models/icon.model";

@Injectable({
  providedIn: 'root'
})
export class StateService {

  isNavOpen$ = new Subject<boolean>()
  selectedSkill$ = new Subject<Icon>()

  constructor() { }
}
