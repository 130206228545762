import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ContactFormService} from "../services/contact-form/contact-form.service";
import {Subscription} from "rxjs";
import {ContactMessage} from "../models/contact-message.model";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgOptimizedImage
    ],
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {

    private contactFormRes$: Subscription = new Subscription();
    private contactFormName = 'contact-form';
    private isMessageSent: boolean = false;

    formData: FormGroup;
    formSections = [
        {
            id: 'name',
            headerName: 'Full Name',
            placeholder: 'Enter name here',
            defaultMessage: 'Enter name here',
            isTextArea: false
        },
        {
            id: 'email',
            headerName: 'Email',
            placeholder: 'Enter email here',
            defaultMessage: 'Enter email here',
            isTextArea: false

        },
        {
            id: 'subject',
            headerName: 'Subject',
            placeholder: 'Enter subject here',
            defaultMessage: 'Enter subject here',
            isTextArea: false

        },
        {
            id: 'message',
            headerName: 'Message',
            placeholder: 'Enter message here',
            defaultMessage: 'Enter message here',
            isTextArea: true
        }
    ]

    constructor(private builder: FormBuilder, private contactService: ContactFormService) {
        this.formData = this.builder.group({
            name: new FormControl('', [Validators.required, Validators.minLength(4)]),
            email: new FormControl('',
                [
                    Validators.required,
                    // Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
                    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
                ]),
            subject: new FormControl('', [Validators.required, Validators.minLength(4)]),
            message: new FormControl('', [Validators.required, Validators.minLength(10)])
        })
    }

    ngOnInit() {

    }

    onInputFocus(inputName: string): void {
        const focusedPlaceholder =
            this.formSections.filter(el => el.id === inputName);
        focusedPlaceholder[0].placeholder = ''

    }

    onInputBlur(inputName: string): void {
        const focusedPlaceholder =
            this.formSections.find(el => el.id === inputName);
        if (this.formData.get(inputName)?.value === '') {
            if (focusedPlaceholder) {
                focusedPlaceholder.placeholder = focusedPlaceholder.defaultMessage;
            }
        }
        ;
    }

    onSubmit(formData: FormGroup) {
        //event.preventDefault();
        const contactMessage = this.getFormData();
        // this.contactFormRes$ = this.contactService.onFormSubmit(contactMessage, this.contactFormName).subscribe({
        //     complete: () => {
        //         this.isMessageSent = true
        //     }
        // });

        const isFormInvalid = this.onEntriesInvalid(formData);

        // if (!isFormInvalid) {
        //     this.contactFormRes$ = this.contactService.onFormSubmit(contactMessage, this.contactFormName)
        //         .subscribe(
        //             (res) => {
        //                 this.isMessageSent = true;
        //                 setTimeout(() => {
        //                     this.isMessageSent = false;
        //                 }, 10000);
        //                 formData.reset();
        //                 alert('Message sent successfully!');
        //                 console.log(res);
        //
        //             },
        //             (err) => {
        //                 this.isMessageSent = false
        //                 console.log(err);
        //             }
        //         );
        // }

        if (!isFormInvalid) {
            this.contactFormRes$ = this.contactService.onFormSubmit(contactMessage, this.contactFormName)
                .subscribe({
                        next: (res) => {
                            this.isMessageSent = true;
                            setTimeout(() => {
                                this.isMessageSent = false;
                            }, 10000);
                            formData.reset();
                            alert('Message sent successfully!');
                            console.log(res);
                        },
                        error: (err) => {
                            this.isMessageSent = false
                            console.log(err);
                        }
                    }
                );
        }
    }

    private getFormData(): ContactMessage {
        return new ContactMessage(
            this.formData.get('name')?.value,
            this.formData.get('email')?.value,
            this.formData.get('subject')?.value,
            this.formData.get('message')?.value
        )
    }

    private onEntriesInvalid(formGroup: FormGroup): boolean {
        if (formGroup.invalid) {
            let errorMessage = 'Message send failed. The following items are invalid: ';
            Object.keys(formGroup.controls).forEach((key) => {
                if (formGroup.get(key)?.invalid) {
                    errorMessage = errorMessage + '\n \u2022' + ' ' + key.toUpperCase()
                }
            })
            alert(errorMessage)
            return true;
        }
        return false;
    }

    ngOnDestroy() {
        this.contactFormRes$ ? this.contactFormRes$.unsubscribe() : null;
    }


}
