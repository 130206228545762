<div class="about-me-container">
    <div class="about-me-title-container">
        <div class="about-me-title-content-container">
            <img class="about-me-title-image"
                 ngSrc="assets/images/background/about-me-title-cloud-800w.png" alt="about-me-cloud" height="574" width="800">
            <p class="about-me-title-main-text">Full-Stack<br>Developer</p>
            <p class="about-me-title-sub-text">Always creating.</p>
        </div>
    </div>
    <div class="about-me-info-container">
        <div class="about-me-summary-container">
            <img class="about-me-summary-image"
                 ngSrc="assets/images/background/blu-brick2.png" alt="brick" height="1493" width="1242">
            <p class="about-me-summary-text">
                Hello, I'm Zane—an experienced professional with an 8-year track record of contributing to top-tier companies in Columbus, Ohio, to drive the development of exceptional products.
                Currently serving as a Senior Full Stack Engineer at Cardinal Health, I'm deeply dedicated to crafting innovative solutions that make a lasting impact.
                I invite you to explore my portfolio, and I'm excited about the prospect of collaborating to create something truly remarkable.
                Let's connect and shape greatness together!
            </p>
        </div>
        <div class="about-me-info-profile-picture-container">
            <img class="about-me-info-profile-picture" ngSrc="assets/images/profile-pic.jpg"
                 height="1092" width="956" alt="profile-pic" >
        </div>
    </div>
    <div class="resume-container">
        <div class="resume-content-container">
            <img class="resume-text-blurb"
                 ngSrc="assets/images/icons/speech-rectangle.jpeg"
                 (click)="openResume()" height="124" width="364" alt="resume-text-blurb">
            <p class="resume-text" (click)="openResume()">Read all about it!</p>
            <img class="resume-icon" ngSrc="assets/images/icons/newspaper.png" (click)="openResume()" height="384"
                 width="555" alt="resume-icon">
        </div>
    </div>
</div>