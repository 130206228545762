import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {StateService} from "../services/state/state.service";
import {Subject} from "rxjs";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";

@Component({
    selector: 'app-navigation-menu',
    templateUrl: './navigation-menu.component.html',
    standalone: true,
    imports: [
        NgForOf,
        NgIf,
        NgOptimizedImage
    ],
    styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit {

    resumeSource = 'assets/resume/zane-jackson-resume-2024.pdf'
    isNavMenuOpen: boolean = false;
    linkedinHover: boolean = false;
    resumeHover: boolean = false;
    closeHover: boolean = false;

    $linkedinHover: Subject<boolean> = new Subject<boolean>();
    $resumeHover: Subject<boolean> = new Subject<boolean>();
    $closeHover: Subject<boolean> = new Subject<boolean>();

    menuOptions: any = [
        {
            displayName: 'Home',
            fragmentName: 'home'
        },
        {
            displayName: 'Development stack',
            fragmentName: 'stack'
        },
        {
            displayName: 'Skills',
            fragmentName: 'skills'
        },
        {
            displayName: 'Experience',
            fragmentName: 'experience'
        },
        {
            displayName: 'Contact me',
            fragmentName: 'contact'
        },
    ];


    constructor(private router: Router, private stateService: StateService) {
    }

    ngOnInit(): void {
        this.$closeHover.subscribe(closeHoverStatus => this.closeHover = closeHoverStatus);
        this.$resumeHover.subscribe(resumeHoverStatus => this.resumeHover = resumeHoverStatus);
        this.$linkedinHover.subscribe(linkedinHoverStatus => this.linkedinHover = linkedinHoverStatus);
        this.stateService.isNavOpen$.subscribe((isNavOpen) => {
            this.isNavMenuOpen = isNavOpen;
        })
    }

    scrollToLocation(fragment: string): void {
        this.stateService.isNavOpen$.next(false);
        this.router.navigate([''], {fragment: fragment, skipLocationChange: true}).then();
    }

    onNavMenuClose(): void {
        this.stateService.isNavOpen$.next(false);
        this.$closeHover.next(false);
    }

    onMouseEnter(icon: string): void {
        if (icon === 'close') {
            this.$closeHover.next(true);
        }
        if (icon === 'linkedin') {
            this.$linkedinHover.next(true);
        }
        if (icon === 'resume') {
            this.$resumeHover.next(true);
        }

    }

    onMouseLeave(icon: string): void {
        if (icon === 'close') {
            this.$closeHover.next(false);
        }
        if (icon ===  'linkedin') {
            this.$linkedinHover.next(false);
        }
        if (icon === 'resume') {
            this.$resumeHover.next(false);
        }
    }

    openResume(): void {
        window.open(this.resumeSource, '_blank');
    }

    openLinkedIn(): void {
        window.open('https://www.linkedin.com/in/zane-jackson-43188358/', '_blank');
    }
}
