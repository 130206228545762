import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {catchError, Observable, Subject, throwError} from "rxjs";
import {ContactMessage} from "../../models/contact-message.model";
import {FormGroup} from "@angular/forms";


@Injectable({
    providedIn: 'root'
})
export class ContactFormService {

    constructor(private http: HttpClient) {
    }



    // May need to add event prevent default
    onFormSubmit(message: ContactMessage, formName: string): Observable<any> {
        const entry = new HttpParams({
                fromObject: {
                    'form-name': formName,
                    ...message
                }
            });

        return this.submitEntry(entry);
    }

    private submitEntry(entry: HttpParams): Observable<any> {
        return this.http.post(
            '/',
            entry.toString(),
            {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                responseType: 'text'
            }
        ).pipe(catchError(this.handleError));
    }

    private handleError(err: HttpErrorResponse) {
        let errMsg = '';

        if (err.error instanceof ErrorEvent) {
            errMsg = `A client-side error occurred: ${err.error.message}`;
        } else {
            errMsg = `A server-side error occurred. Code: ${err.status}. Message: ${err.message}`;
        }
        alert('This service is unavailable at this time. Feel free to reach out to me at zane.jackson.mail@gmail.com ')
        return throwError(() => errMsg);
    }
}
