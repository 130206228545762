import {ApplicationConfig} from '@angular/core';
import {
    ExtraOptions,
    InMemoryScrollingOptions,
    provideRouter,
    RouterConfigOptions,
    withInMemoryScrolling, withRouterConfig
} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {provideHttpClient} from "@angular/common/http";
import {ViewportScroller} from "@angular/common";




const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
};

const scrollConfigExtraOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 100]
}


export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withRouterConfig(scrollConfigExtraOptions), withInMemoryScrolling(scrollConfigExtraOptions)),
        provideClientHydration(),
        provideHttpClient()]
};