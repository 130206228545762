<div class="nav-container" *ngIf="isNavMenuOpen">
    <div class="nav-menu-blank-side" (click)="onNavMenuClose()">
    </div>
    <div class="nav-menu-container">
        <div class="nav-menu-close-container">
            <div class="nav-menu-close-container-item" (click)="onNavMenuClose()"
                 (mouseenter)="onMouseEnter('close')" (mouseleave)="onMouseLeave('close')">
                <img *ngIf="!closeHover" class="close-icon" ngSrc="assets/images/icons/close-icon.svg" height="128"
                     width="128" alt="close">
                <img *ngIf="closeHover" class="close-icon" ngSrc="assets/images/icons/close-icon-hover.svg" height="128"
                     width="128" alt="close-hover">
            </div>
        </div>
        <div class="nav-menu">
            <p class="nav-menu-title">Menu</p>
            <div class="nav-menu-options">
                <div *ngFor="let option of menuOptions; let i = index">
                    <div class="nav-menu-li">
                        <p class="nav-menu-li-link" (click)="scrollToLocation(option.fragmentName)">
                            {{option.displayName}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="nav-menu-footer-container">
            <div class="nav-menu-footer-item" (click)="openLinkedIn()" (mouseenter)="onMouseEnter('linkedin')" (mouseleave)="onMouseLeave('linkedin')">
                <p class="nav-menu-footer-text" >LinkedIn</p>
                <img *ngIf="!linkedinHover" class="nav-menu-footer-icon" ngSrc="assets/images/icons/nav/linkedin.svg"
                     height="24" width="24" alt="linkedin-icon">
                <img *ngIf="linkedinHover" class="nav-menu-footer-icon" ngSrc="assets/images/icons/nav/linkedin-hover.svg"
                     height="24" width="24" alt="linkedin-icon-hover">
            </div>
            <div class="nav-menu-footer-item" (click)="openResume()" (mouseenter)="onMouseEnter('resume')" (mouseleave)="onMouseLeave('resume')">
                <p class="nav-menu-footer-text">Resume</p>
                <img *ngIf="!resumeHover" class="nav-menu-footer-icon" ngSrc="assets/images/icons/nav/resume.svg"
                     height="24" width="24" alt="resume-icon">
                <img *ngIf="resumeHover" class="nav-menu-footer-icon" ngSrc="assets/images/icons/nav/resume-hover.svg"
                     height="24" width="24" alt="resume-icon-hover">
            </div>
        </div>
    </div>
</div>

